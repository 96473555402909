import React from "react";
import { css } from "@emotion/core";
import {
  BlurbWrapper,
  BlurbText,
  GreenLinkButton,
  FullPageWhiteSection,
} from "../components/styled-components";

import MainLayout from "../components/main-layout";

const FourOhFour = () => {
  return (
    <>
      <MainLayout>
        <FullPageWhiteSection>
          <div
            css={css`
              width: 100%;
              height: 80vh;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            `}
          >
            <BlurbWrapper>
              <BlurbText color="#1a4428">
                We apologize, it looks like something might have went wrong!
              </BlurbText>
            </BlurbWrapper>
            <br />
            <br />
            <GreenLinkButton to="/" stripHash>
              GO HOME
            </GreenLinkButton>
          </div>
        </FullPageWhiteSection>
      </MainLayout>
    </>
  );
};

export default FourOhFour;
